import { styled } from "goober";
import { Card } from "clutch/src/Card/Card.jsx";

export const CoachingSection = styled(Card)`
  & {
    display: grid;
    gap: var(--sp-4);
    padding: var(--sp-9);
    position: relative;

    @container (inline-size <= 675px) {
      gap: var(--sp-2);
      padding: var(--sp-6);
    }
    @container (inline-size <= 575px) {
      padding: var(--sp-4);
    }
  }

  > * {
    position: relative;
  }

  .background-image {
    position: absolute;
    mask-image: radial-gradient(at top center, black 30%, transparent 70%);
    opacity: 0.2;
  }

  .rank-comparison {
    display: flex;
    align-items: center;
    gap: var(--sp-2);

    @container (inline-size <= 675px) {
      display: grid;
    }
  }
  .rank-comparison-title {
    span {
      font-weight: 600;
      color: var(--rank-comparison-color, var(--purple));
    }
  }

  .coaching-content {
    display: grid;
    grid-template-columns: 18rem 1fr;
    gap: var(--sp-9);

    @container (inline-size <= 675px) {
      grid-template-columns: 16rem 1fr;
      gap: var(--sp-5);
    }
    @container (inline-size <= 575px) {
      grid-template-columns: 1fr;
      grid-auto-rows: max-content;
      gap: var(--sp-2);
    }
  }

  .feedback {
    display: grid;
    grid-auto-rows: min-content;
    gap: var(--sp-6);
    text-shadow: 0 4px 15px var(--shade9);
  }
  .feedback-header {
    display: grid;
    grid-auto-rows: min-content;
    gap: var(--sp-2);
    padding-block-start: var(--sp-2);
  }
  .title-supertitle {
    display: grid;
    grid-auto-rows: min-content;
    gap: var(--sp-2);
  }
  .supertitle {
    color: var(--shade1);
  }
  .title {
    font-size: var(--sp-6);
    line-height: var(--sp-8);
    font-weight: 600;

    span {
      color: var(--title-highlight, var(--yellow));
      font-weight: 600;
    }
  }
  .subtitle {
    color: var(--subtitle-highlight, var(--shade1));

    span {
      color: var(--shade0);
      font-weight: 600;
    }
  }
`;

export const RankValueCompare = styled("div")`
  & {
    display: grid;
    grid-auto-rows: min-content;
    gap: var(--sp-2);
  }
  .pre-title {
    color: var(--shade1);

    span {
      color: var(--shade0);
      font-weight: 600;
    }
  }
  .stat-graphic {
    display: grid;
    grid-auto-rows: min-content;
    gap: var(--sp-1);
  }
  .bar {
    --radii: var(--br);
    --bar-color: var(--shade1);
    --bg-fill-stop: calc(var(--fill-percentage) * 100%);
    --bg-fill-color: color-mix(in hsl, var(--bar-color), transparent 85%);
    display: flex;
    align-items: stretch;
    height: var(--sp-9);
    color: var(--bar-color);

    .bar-left,
    .bar-left::after,
    .bar-left img {
      border-top-left-radius: var(--radii);
      border-bottom-left-radius: var(--radii);
    }
    .bar-left {
      position: relative;
      display: grid;
      place-items: center;
      aspect-ratio: 1;
      background: color-mix(in hsl, var(--bar-color), transparent 75%);

      &::after {
        content: "";
        position: absolute;
        inset: 0;
        box-shadow:
          inset 1px 0 var(--bg-fill-color),
          inset 0 -1px var(--bg-fill-color),
          inset 0 1px var(--bg-fill-color),
          1px 0 var(--bg-fill-color);
      }

      img {
        width: var(--sp-9);
        height: var(--sp-9);
      }
      svg {
        width: var(--sp-6);
        height: var(--sp-6);
      }
    }
    .bar-right {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-inline: var(--sp-4);
      background: linear-gradient(
        to right,
        var(--bg-fill-color) var(--bg-fill-stop),
        transparent var(--bg-fill-stop)
      );
      box-shadow: inset 0 0 0 1px var(--bg-fill-color);
      border-top-right-radius: var(--radii);
      border-bottom-right-radius: var(--radii);
    }
  }
  .diff {
    text-align: right;
    padding-inline: var(--sp-4);
    color: var(--diff-color);
  }
`;

export const CardsCarouselContainer = styled("div")`
  & {
    display: grid;
    grid-auto-rows: min-content;
  }

  .cards-stack {
    display: grid;

    > * {
      grid-area: 1 / 1;
    }
  }

  .card-contents {
    --card-surface: var(--shade7);
    box-shadow: 0 var(--sp-5) var(--sp-12) var(--shade10-25);
  }

  .cards-nav {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cards-nav-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--sp-1);
    padding: var(--sp-4) var(--sp-2);
    background: transparent;
    color: var(--shade2);
    cursor: pointer;
    transition: color var(--transition);

    &:hover,
    &[data-active="true"] {
      color: var(--shade0);
    }

    svg {
      width: var(--sp-4);
      height: auto;
    }
  }

  @keyframes fade-in-left {
    from {
      opacity: 0;
      translate: 100% 0;
    }
  }
  @keyframes fade-out-left {
    to {
      opacity: 0;
      translate: -100% 0;
    }
  }
`;
