import React, { useState } from "react";
import { flushSync } from "react-dom";
import { Trans, useTranslation } from "react-i18next";

import {
  CardsCarouselContainer,
  CoachingSection,
} from "@/shared/PostMatchCoachingSection.style.jsx";
import globals from "@/util/global-whitelist.mjs";
import { sendInteractionEvent } from "@/util/use-interaction-event.mjs";

export type PostMatchCoachingSectionProps = {
  backgroundImage?: {
    src: string;
    onError?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
    style?: React.CSSProperties;
  };
  rankComparison: {
    color: string;
    titleSnippet: string;
    rankName: string;
    rankValue: number;
    icon?: React.ReactNode;
    rankSelect?: React.ReactNode;
  };
  performanceCategories?: CoachingCategory[];
  defaultIndex?: number;
};

export type CoachingCategory = {
  titleShort: Translation;
  icon?: React.ReactNode;
  card: React.ReactNode;
  supertitle: {
    text: Translation;
  };
  title: {
    text: React.ReactNode;
    spanColor?: string;
  };
  subtitle?: {
    text: React.ReactNode;
    spanColor?: string;
  };
  content?: React.ReactNode;
};

function PostMatchCoachingSection({
  backgroundImage,
  rankComparison,
  performanceCategories,
  defaultIndex = 0,
}: PostMatchCoachingSectionProps) {
  const { t } = useTranslation();

  const [manuallySelectedIndex, setManuallySelectedIndex] = useState<
    number | null
  >(null);
  const [currentIndex, _setCurrentIndex] = useState(
    manuallySelectedIndex ?? defaultIndex,
  );

  function setManualIndex(index: number) {
    if ("startViewTransition" in globals.document) {
      /* eslint-disable @typescript-eslint/no-explicit-any */ (
        globals.document as any
      ).startViewTransition(() => {
        flushSync(() => {
          setManuallySelectedIndex(index);
        });
      });
      return;
    }

    setManuallySelectedIndex(index);
  }

  const activeIndex = manuallySelectedIndex ?? currentIndex;
  const selectedCategory = performanceCategories?.[activeIndex];

  return (
    <CoachingSection
      style={{
        "--title-highlight": selectedCategory.title.spanColor || "",
        "--subtitle-highlight": selectedCategory.subtitle.spanColor || "",
        "--rank-comparison-color": rankComparison.color,
      }}
    >
      {backgroundImage && (
        <img
          src={backgroundImage.src}
          onError={backgroundImage.onError}
          className="background-image"
          style={backgroundImage.style}
        />
      )}
      <header className="rank-comparison">
        {rankComparison.icon}
        {rankComparison.rankSelect}
        <span className="rank-comparison-title">
          <Trans
            i18nKey="common:coaching.yourPerformanceComparedTo"
            values={{ title: rankComparison.titleSnippet }}
          >
            Your performance compared to other {/* @ts-ignore */}
            <span>{{ title: rankComparison.titleSnippet }}</span> players
          </Trans>
        </span>
      </header>
      <div className="coaching-content">
        <CardsCarouselContainer>
          <div className="cards-stack">{selectedCategory?.card}</div>
          <div className="cards-nav">
            {performanceCategories.map((category, i) => {
              return (
                <button
                  key={i}
                  onClick={() => {
                    setManualIndex(i);
                    sendInteractionEvent("post-match-coaching", {
                      type: "category-click",
                      category: category.titleShort[1],
                    });
                  }}
                  data-active={i === activeIndex}
                  className="cards-nav-button type-caption--semi"
                >
                  {category.icon}
                  <span>{t(...category.titleShort)}</span>
                </button>
              );
            })}
          </div>
        </CardsCarouselContainer>
        <div className="feedback">
          <div className="feedback-header">
            <div className="title-supertitle">
              <span className="supertitle type-title--semi">
                {Array.isArray(selectedCategory.supertitle.text)
                  ? t(...selectedCategory.supertitle.text)
                  : selectedCategory.supertitle.text || ""}
              </span>
              <h3 className="title">{selectedCategory.title.text || ""}</h3>
            </div>
            {selectedCategory.subtitle && (
              <p className="subtitle type-subtitle--semi">
                {selectedCategory.subtitle.text || ""}
              </p>
            )}
          </div>
          {selectedCategory.content}
        </div>
      </div>
    </CoachingSection>
  );
}

export default PostMatchCoachingSection;
