import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";
import type { SelectValue } from "clutch/src/Select/Select";
import { Select } from "clutch/src/Select/Select";

import { readState } from "@/__main__/app-state.mjs";
import router, { updateRoute } from "@/__main__/router.mjs";
// import RunesStatsCard from "@/game-lol/components/RunesStatsCard.jsx";
import { DEFAULT_COMPARISON_RANK } from "@/game-lol/constants/coaching-constants.mjs";
import LolColors from "@/game-lol/constants/colors.mjs";
import {
  RANK_SYMBOL_TO_STR,
  RANK_SYMBOLS,
  ROLE_SYMBOL_TO_STR,
  ROLE_SYMBOLS,
} from "@/game-lol/constants/constants.mjs";
import {
  getPreferredPostmatchComparisonRank,
  updatePreferredPostmatchComparisonRank,
} from "@/game-lol/utils/actions.mjs";
import type { PlayerPerformance } from "@/game-lol/utils/coaching-types.mjs";
import { coachingPerformance } from "@/game-lol/utils/coaching-utils.mjs";
import getRankIcon from "@/game-lol/utils/get-rank-icon.mjs";
import { calcStartOfEndGame } from "@/game-lol/utils/match-utils.mjs";
import Static from "@/game-lol/utils/static.mjs";
import QueueSymbol from "@/game-lol/utils/symbol-queue.mjs";
import { RankSymbol } from "@/game-lol/utils/symbol-rank.mjs";
import RoleSymbol from "@/game-lol/utils/symbol-role.mjs";
import {
  coachingStatsKey,
  coachingStatsVariables,
  getStaticData,
} from "@/game-lol/utils/util.mjs";
import { OverlayMessage, PerfGrid } from "@/shared/Performance.style.jsx";
// import RuneIcon from "@/inline-assets/hextech-rune-icon.svg";
import PerformanceCard from "@/shared/PerformanceCard.jsx";
import PerformanceInputStats from "@/shared/PerformanceInputStats.jsx";
import type { CoachingCategory } from "@/shared/PostMatchCoachingSection.jsx";
import PostMatchCoachingSection from "@/shared/PostMatchCoachingSection.jsx";
import { devLog } from "@/util/dev.mjs";
import { getLocale } from "@/util/i18n-helper.mjs";
import { sendInteractionEvent } from "@/util/use-interaction-event.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function PostMatchCoaching({ localPlayer, match, timeline }) {
  const { t } = useTranslation();
  const { route } = router;
  const {
    lol: { championStatsByMin },
    volatile: { lolCurrentSkinSelection },
  } = useSnapshot(readState);

  const champions = getStaticData("champions");
  const championId = localPlayer.championId;
  const championKey = champions?.keys?.[localPlayer.championId];
  const championName = champions?.[championKey]?.name;

  const [cardBgImg, setCardBgImg] = useState(null);
  useEffect(() => {
    if (!championId) return;
    if (lolCurrentSkinSelection?.selectedChampionId === championId) {
      setCardBgImg(
        Static.getChampionCenterSkinImage(
          championId,
          lolCurrentSkinSelection?.selectedSkinId,
        ),
      );
    } else {
      setCardBgImg(Static.getChampionCenterImage(championId));
    }
  }, [championId, lolCurrentSkinSelection]);

  const preferredComparisonRank = getPreferredPostmatchComparisonRank();
  const [playerRankSymbol, setPlayerRankSymbol] = useState(
    preferredComparisonRank || localPlayer.rank || DEFAULT_COMPARISON_RANK,
  );
  const playerRankDetails = RANK_SYMBOL_TO_STR[playerRankSymbol];
  const playerRankName = t(
    playerRankDetails.t.name,
    playerRankDetails.t.fallback,
  );
  const playerRankColors = LolColors.ranks?.[playerRankDetails?.key];
  const playerRankLabel = [
    playerRankDetails.t.name,
    playerRankDetails.t.fallback,
  ] as Translation;

  const playerRoleSymbol = RoleSymbol(localPlayer.teamPosition);
  const playerRole = ROLE_SYMBOL_TO_STR[playerRoleSymbol];

  const coachingVars = coachingStatsVariables({
    championId: localPlayer.championId,
    tier: playerRankSymbol,
    role: playerRoleSymbol,
    queue: QueueSymbol(match.queueId),
  });

  const benchmarkData = championStatsByMin?.[coachingStatsKey(coachingVars)];

  const performance = useMemo(() => {
    const perf: PlayerPerformance = coachingPerformance({
      localPlayer,
      playerRankDetails,
      match,
      timeline,
      benchmarkData,
      queue: QueueSymbol(match.queueId),
      lateGameStartTimestamp: calcStartOfEndGame(timeline),
      playerRank: playerRankSymbol,
    });
    return perf;
  }, [
    localPlayer,
    playerRankDetails,
    match,
    timeline,
    benchmarkData,
    playerRankSymbol,
  ]);

  const { tooShort, loading, categories } = performance;

  devLog("[Postmatch Coaching]", categories);

  const isSupport = localPlayer.teamPosition === ROLE_SYMBOLS.support;

  const mostImpactfulCategory = isSupport
    ? "combat"
    : [...categories].sort(
        (a, b) => a.performance.score - b.performance.score,
      )[0].key;
  const mostImpactfulIndex = categories.findIndex(
    (c) => c.key === mostImpactfulCategory,
  );

  const performanceCategories: CoachingCategory[] = categories.map(
    (category) => {
      const Icon = category.icon;

      return {
        titleShort: category.title,
        icon: <Icon />,
        card: (
          <PerformanceCard
            title={category.title}
            score={category.performance.score}
            infoTooltip={
              category.explanation ? t(...category.explanation) : null
            }
            rankColor={playerRankColors?.fill}
            subContent={
              <PerformanceInputStats
                rows={category.stats.map((stat) => {
                  const { score, disabled } = stat;
                  const tooltipDescription: Translation =
                    score.val < score.mean
                      ? [
                          "common:coaching.youDidAmountWorseThanAverageRankCharacter",
                          "You did {{amount}} worse than the average {{rank}} {{character}}",
                          {
                            amount: (1 - score.val / score.mean).toLocaleString(
                              getLocale(),
                              {
                                style: "percent",
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              },
                            ),
                            rank: playerRankName,
                            character: championName,
                          },
                        ]
                      : [
                          "common:coaching.youDidAmountBetterThanAverageRankCharacter",
                          "You did {{amount}} better than the average {{rank}} {{character}}",
                          {
                            amount: (score.val / score.mean - 1).toLocaleString(
                              getLocale(),
                              {
                                style: "percent",
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              },
                            ),
                            rank: playerRankName,
                            character: championName,
                          },
                        ];

                  const tooltipOptions = {
                    description: tooltipDescription,
                    min: {
                      label: [
                        "lol:rankNameBot25Percent",
                        "{{rankName}} Bot 25%",
                        {
                          rankName: playerRankName,
                        },
                      ] as Translation,
                      labelColor: playerRankColors?.fill || "var(--shade1)",
                    },
                    max: {
                      label: [
                        "lol:rankNameTop25Percent",
                        "{{rankName}} Top 25%",
                        {
                          rankName: playerRankName,
                        },
                      ] as Translation,
                      labelColor: playerRankColors?.fill || "var(--shade1)",
                    },
                    mean: {
                      label: [
                        "lol:rankNameAvg",
                        "{{rankName}} Avg",
                        {
                          rankName: playerRankName,
                        },
                      ] as Translation,
                      labelColor: playerRankColors?.fill || "var(--shade1)",
                    },
                  };

                  return {
                    disabled,
                    label: Array.isArray(stat.title)
                      ? t(...stat.title)
                      : stat.title,
                    valueDisplay: score.val.toLocaleString(
                      getLocale(),
                      stat.format,
                    ),
                    value: score.val,
                    format: stat.format,
                    fill: score.score,
                    min: score.min,
                    max: score.max,
                    mean: score.mean,
                    tooltipOptions,
                  };
                })}
                onMouseOver={() => {
                  sendInteractionEvent("post-match-coaching", {
                    type: "substat-hover",
                  });
                }}
              />
            }
          />
        ),
        supertitle: {
          text: category.feedback.supertitle,
        },
        title: {
          text: category.feedback.title,
          spanColor: category.feedback.titleHighlightColor,
        },
        subtitle: {
          text: null,
          spanColor: "",
        },
        content: category.feedback.content || null,
      };
    },
  );

  // Currently no feedback to give when runes is selected
  // maybe add back if there is a nice way to to handle this
  // performanceCategories.push({
  //   titleShort: ["lol:runes.runes", "Runes"],
  //   icon: <RuneIcon />,
  //   card: <RunesStatsCard player={localPlayer} />,
  //   supertitle: {
  //     text: ["lol:runes.runes", "Runes"],
  //   },
  //   title: {
  //     text: <span>{"Runes title"}</span>,
  //   },
  //   subtitle: {
  //     text: <span>{"Runes subtitle"}</span>,
  //   },
  //   content: null,
  // });

  if (loading) {
    return (
      <Card
        loading
        style={{ height: mostImpactfulCategory === "combat" ? 586 : 566 }}
      />
    );
  } else if (tooShort) {
    return (
      <PerfGrid className="span-3" style={{ "--cols": 1 }}>
        <Card style={{ height: 584 }} />
        <OverlayMessage>
          {t(
            "common:performance.matchTooShort",
            "Match too short to accurately analyze.",
          )}
        </OverlayMessage>
      </PerfGrid>
    );
  }

  const tiers = Object.getOwnPropertySymbols(RANK_SYMBOL_TO_STR)
    .filter(
      (key) =>
        key !== RANK_SYMBOLS.platinumPlus &&
        key !== RANK_SYMBOLS.emeraldPlus &&
        key !== RANK_SYMBOLS.diamondPlus &&
        key !== RANK_SYMBOLS.masterPlus &&
        key !== RANK_SYMBOLS.masterPlus,
    )
    .map((key) => {
      const tierData = RANK_SYMBOL_TO_STR[key];
      const Icon = getRankIcon(tierData.key);

      return {
        value: tierData.key,
        text: [tierData.t.name, tierData.t.fallback],
        icon: Icon && <Icon />,
      };
    }) as readonly SelectValue<string>[];

  return (
    <PostMatchCoachingSection
      backgroundImage={{
        src: cardBgImg,
        onError: (ev) => {
          if (!localPlayer?.championId) return;
          const defaultImg = Static.getChampionCenterImage(
            localPlayer?.championId,
          );
          const target = ev.target as HTMLImageElement;
          if (target.src !== defaultImg) setCardBgImg(defaultImg);
        },
        style: {
          width: "60rem",
          height: "auto",
          top: 0,
          right: 0,
          translate: "25% -20%",
        },
      }}
      rankComparison={{
        // icon: <RankIcon width="28" height="28" />,
        color: playerRankDetails.color,
        titleSnippet: t(
          "lol:rankChampionRole",
          "{{rankName}} {{championName}} {{roleName}}",
          {
            rankName: t(...playerRankLabel),
            championName,
            roleName: playerRole?.label && t(...playerRole.label),
          },
        ),
        rankName: t(playerRankDetails.t.name, playerRankDetails.t.fallback),
        rankValue: 4771,
        rankSelect: (
          <Select
            selected={playerRankDetails.key}
            options={tiers}
            onChange={(v) => {
              const rankSymbol = RankSymbol(v);
              setPlayerRankSymbol(rankSymbol);
              updatePreferredPostmatchComparisonRank(rankSymbol);
              updateRoute(route.currentPath, route.searchParams, {
                comparisonRank: v,
              });
              sendInteractionEvent("post-match-coaching", {
                type: "rank-select",
                rank: v,
              });
            }}
          />
        ),
      }}
      defaultIndex={mostImpactfulIndex}
      performanceCategories={performanceCategories}
    />
  );
}

export default PostMatchCoaching;
